import React from "react";
const toData = [
  {
    class: "content0",

    src: <img id="imglogo" src={require("./stock-earnings.svg")} />,
    text1: "Stock market web app ",
    text2: 
     
        "Built a stock market app showcasing various forms of data alowing users a vast array of functionality"
    ,
    text3: "Developed with Html, Css , Js, Firebase , Ionic and React",
    link2: "https://github.com/YAOfficial/stocksy",
    link1: "https://yusuf.is-a.dev/stocksy/",
  },

  {
    class: "content3",
    src: <img src={require("./virus.png")} />,
    text1: "Covid-19 web app ",
    text2:
      "Built a coronavirus dashboard showcasing various forms of data relevant to coronavrius (covid-19) ",
    text3: "Developed using Html, Css, js, React, leaflet, gatsby & rechart ",
    link2: "https://github.com/YAOfficial/covidreport",
    link1: "https://cov1dreport.netlify.app/",
  },
  {
    class: "content4",

    src: <img id="imglogo" src={require("./sw.png")} />,
    text1: "Full Stack social media app",
    text2: 
     
        "Built a social media app following solidsails course  alowing users a vast array of functionality"
    ,
    text3: "Developed with Html, Css , Js, Firebase , Ionic and React",
    link2: "https://github.com/YAOfficial/Newzy",
    link1: "https://newz-c60cc.web.app/news",
  },
];

export default toData;
